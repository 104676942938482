import {storageService} from './storageService'

class LanguageService {

    constructor() {
        this.languageList = ['sr', 'en']
        this.defaultLanguage = 'sr'
    }

    setActiveLanguage(language) {
        storageService.set('lang', language)
        this.activeLanguage = storageService.get('lang')
    }

    getActiveLanguage() {
        const activeLanguage = storageService.get('lang')

        return activeLanguage || this.defaultLanguage
    }

}

export const languageService = new LanguageService()