import React from 'react'

import { withTranslation } from 'react-i18next'

import AppHelmet from '../components/AppHelmet'
import SloganSlider from './Slogan/SloganSlider'
import About from './About/About'
import Services from './Services/Services'
import Team from './Team/Team'

const Home = ({ t }) => {
    return (
        <AppHelmet title={t('title')} description={t('description')}>
            <main>
                <SloganSlider />
                <About />
                <Services />
                <Team />
            </main>
        </AppHelmet>
    )
}

export default withTranslation()(Home)