import React from "react";

import { Helmet } from "react-helmet";

const AppHelmet = ({ children, title, description }) => (
    <>
        <Helmet>
            <title>{title}</title>

            <meta
                name="description"
                content={description}
            />
        </Helmet>
        {children}
    </>
)

export default AppHelmet