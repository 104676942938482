import React, { useState } from 'react'

import { withTranslation } from 'react-i18next'

import { languageService } from '../../services/languageService'

import './Header.css'

const Header = ({ t }) => {
    const [showMobileNav, setShowMobileNav] = useState(false)
    const [activeLang, setActiveLang] = useState(languageService.getActiveLanguage())

    const onToggleMobileNav = () => {
        setShowMobileNav(!showMobileNav)
    }


    const handleActiveLang = (e) => {

        const activeLang = e.target.getAttribute('data-lang')

        languageService.setActiveLanguage(activeLang)
        setActiveLang(activeLang)

        window.location.reload()
    }

    return (
        <header className="header">
            <div className="header-info">
                <div className="header-info__container container">
                    <div className="header-info__contact">
                        <span>tel: +381 64 220 77 93</span>
                        <span>email: <a href="mailto:yesagencijabg@gmail.com" >yesagencijabg@gmail.com</a></span>
                    </div>
                    <div className="header-info_lang">
                        <span className={`${activeLang === 'sr' ? 'active' : ''}`} data-lang="sr" onClick={handleActiveLang}>sr</span>
                        /
                     <span className={`${activeLang === 'en' ? 'active' : ''}`} data-lang="en" onClick={handleActiveLang}>en</span>
                    </div>
                </div>
            </div>
            <div className="header-nav container">
                <div className="header-nav__logo">
                    <img src={t('logo')} alt="logo" />
                </div>
                <nav className="header-nav__navigation">
                    {t('nav').map(item => (
                        <a key={item.slug} href={item.slug}>{item.title}</a>
                    ))}
                </nav>
                <div className="header-nav-mobile" onClick={onToggleMobileNav}>
                    <i className="fas fa-bars menu-mob"></i>
                </div>
                <nav className={`header-nav-mobile__navigation ${showMobileNav ? 'show' : ''}`}>
                    {t('nav').map(item => (
                        <a key={item.slug} href={item.slug}>{item.title}</a>
                    ))}
                </nav>
            </div>
        </header>
    )
}

export default withTranslation()(Header)