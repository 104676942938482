import React from 'react'

import { withTranslation } from 'react-i18next'

import './Footer.css'

const Footer = ({ t }) => (
    <footer className="footer">
        <div className="container">
            <div className="footer-container">
                <div className="footer-col footer-about">
                    <h5 className="footer-title">{t('aboutTitle')}</h5>
                    <div className="footer-about__text" dangerouslySetInnerHTML={{ __html: t('aboutText') }} />
                </div>
                <div className="footer-col footer-contact" id="kontakt">
                    <h5 className="footer-title">{t('contact')}</h5>
                    <div className="footer-contact__info">
                        {t('address')}: <span>Jurija Gagarina 105, Novi Beograd</span>
                    </div>
                    <div className="footer-contact__info">
                        Email: <span><a href="mailto:yesagencijabg@gmail.com" >yesagencijabg@gmail.com</a></span>
                    </div>
                    <div className="footer-contact__info">
                        {t('phone')}: <span>+381 064 220 77 93</span>
                    </div>
                    <div className="footer-contact__info">
                        {t('businessHours')}: <span>{t('workingDays')} 9h - 17h</span>
                    </div>
                </div>
                <div className="footer-col footer-map">
                    <iframe title="myMap" width="100%" height="300" src="https://maps.google.com/maps?width=100%&amp;height=300&amp;hl=en&amp;q=Jurija%20Gagarina%20105%20Novi%20Beograd+(My%20Business%20Name)&amp;ie=UTF8&amp;t=&amp;z=16&amp;iwloc=B&amp;output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"><a href="https://www.maps.ie/coordinates.html">find my coordinates</a></iframe>
                </div>
            </div>
            <div className="credit">
                <div>Icons made by <a href="https://www.flaticon.com/authors/photo3idea-studio" title="photo3idea_studio">photo3idea_studio</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
            </div>
        </div>
    </footer>
)

export default withTranslation()(Footer)