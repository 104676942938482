import React from 'react'

import Slider from "react-slick";

import { withTranslation } from 'react-i18next'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './SloganSlider.css'

const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 4000,
    cssEase: "linear"
};

const CustomSlide = (props) => (
    <div {...props} className="slogan-slide" style={{ backgroundImage: `url(${props.image})` }}>
        <h2 className="slogan-text">{props.text}</h2>
    </div>
)

const SloganSlider = ({ t }) => {
    return (
        <>
            <h1 className="main-title">{t('mainTitle')}</h1>
            <Slider {...settings}>
                {t('sloganSlides').map(slide => (
                    <CustomSlide text={slide.text} image={slide.image} key={slide.text} />
                ))}

            </Slider>
        </>
    )
}

export default withTranslation()(SloganSlider)