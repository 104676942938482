import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { languageService } from '../services/languageService'

import translationSR from '../locales/sr/translation.json'
import translationEN from '../locales/en/translation.json'

const resources = {
    sr: {
        translation: translationSR
    },
    en: {
        translation: translationEN
    }
}

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: 'sr',
    lng: languageService.getActiveLanguage() ? languageService.getActiveLanguage() : languageService.defaultLanguage,
    keySeparator: false,
    returnObjects: true
})

export default i18n
