import React from 'react'

import { withTranslation } from 'react-i18next'

import SectionTitle from '../../components/SectionTitle/SectionTitle'

import './Services.css'

const Services = ({t}) => {

    return (
        <section className="services section container" id="usluge">
            <SectionTitle title={t('servicesTitle')} />
            <div className="services-list">
                {t(('services')).map(service => (
                    <div key={service.title} className="services-item">
                        <img className="services-item__icon" src={service.icon} alt="service"/>
                        <h4 className="services-item__title">{service.title}</h4>
                    </div>
                ))}
            </div>
        </section>
    )
}

export default withTranslation()(Services)